module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Digital Avenues","short_name":"Digital Avenues","start_url":"/","background_color":"#f6f8f8","theme_color":"#f6f8f8","display":"minimal-ui","icon":"assets/images/manifest/android-icon-48x48.png","icons":[{"src":"assets/images/manifest/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"assets/images/manifest/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"assets/images/manifest/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"assets/images/manifest/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"assets/images/manifest/android-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"assets/images/manifest/ms-icon-310x310.png","sizes":"310x310","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d9bb23b2e2a00785a32fe2ccaea78342"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-57F1P99GX6"],"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
